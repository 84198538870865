@import 'reset';
@import 'fonts';
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

$requested_color: #D0021B;
$text_color: #FFFFFF;
$black_color: #191a1a;
$basic_line_height: 16px;



body {
  background-color:#121e24;
  transition: 0.5s background-color;
  font-family: 'Post Grotesk', 'Spoqa Han Sans', sans-serif;
  font-size:14px;
  line-height: $basic_line_height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@mixin absolute_middle {
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@mixin absolute_center {
  -moz-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

@mixin absolute_center_middle {
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin phone {
  @media (max-width: 414px) {
    @content;
  }
}

@mixin ipad_portrait {
  @media (max-width: 768px) {
    @content;
  }
}


@mixin ipad_landscape {
  @media (max-width: 1024px) {
    @content;
  }
}


@mixin desktop {
  @media (max-width: 1350px) {
    @content;
  }
}


@mixin s_desktop {
  @media (max-height: 1000px) {
    @content;
  }
}


@mixin arrow_type {
  font-family: 'open-arrow', sans-serif;
  font-size: 0.8em;
  line-height:1.3em;
  font-style:   normal;
  font-weight: 400;

  text-rendering: optimizeLegibility;
}
@mixin regular_han_type {
  font-family: 'Post Grotesk', 'Spoqa Han Sans', sans-serif;
  font-size: 0.8em;
  line-height:1.3em;
  font-style:   normal;
  font-weight: 400;
  word-break: keep-all;
  text-rendering: optimizeLegibility;
}


@mixin regular_type {
  font-family: 'Post Grotesk', 'Spoqa Han Sans', sans-serif;
  font-size: 0.8em;
  line-height:1.15em;
  font-style:   normal;
  font-weight: 400;

  text-rendering: optimizeLegibility;
}

@mixin italic_type {
  font-family: 'Post Grotesk', 'Spoqa Han Sans', sans-serif;
  font-style:   italic;
  font-weight: 400;

  text-rendering: optimizeLegibility;
}

@mixin bold_type {
  font-family: "Post Grotesk", Helvetica, Arial, sans-serif;
  font-style:   normal;
  font-weight: 700;

  line-height:1.3;
  text-rendering: optimizeLegibility;
}

i {
  @include italic_type;
}

b {
  @include bold_type;
}

input {  
  -webkit-box-shadow: none;
  outline: -webkit-focus-ring-color auto 0px;
  border:none;
} 

a {
  text-decoration: none;
  color:$text_color;
}

a:hover {
  opacity:.5;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.fl {
  float:left;
}

.fr {
  float:right;
}

.l-apple-box {
  height:20px;
}


.l-apple-box--half {
  height:10px;
}

.l-apple-box--quarter {
  height:5px;
}

.l-apple-box--double {
  height:40px;
}

br.clearing {
  clear:both !important;
}

sup {
  font-size:0.6em;
  position:relative;
  top:-0.6em;
}

li {
  @include regular_type;
  line-height:1.25;
}

p {
  @include regular_type;

  line-height: 1.25;
  margin-bottom: 15px;
  font-size: 1.0em;

  @include phone {
    font-size:0.85em;
  }
}

h2.subtitle {
  font-size:1.3em;
  margin-bottom:40px;
  @include bold_type;
}

h3.subtitle {
  font-size:1.3em;
  margin-bottom:20px;
  @include bold_type;
}


header {
  padding: 18px 22px;
  width: calc(100% - 44px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 44px);
  @include ipad_landscape {
    padding: 5px 10px;
    width: calc(100% - 20px);
  }
  a {
    text-decoration: none;
  }
}

.main-title {
  @include bold_type;
  font-size:1.3em;

  @include ipad_landscape {
    font-size: 1.3em;
  }
}

.about-area {
  margin-top: 100px;
  width: 650px;

  @include ipad_landscape {
    width: 70%;
  }

  @include phone {
    width: auto;
  }

  &__text {
    @include bold_type;
    font-size: 1.7em;
    line-height: 1.15;

    @include ipad_landscape {
      font-size:1.4em;
    }
  }
}

.creative-projects-subtitle {

  max-width: 1080px;
}

.container {
  margin: 50px 20px;
  max-width: 1080px;

  &--firstpage {
    max-width:none;
    width: calc(100% - 40px);
    margin: 50px auto;

    @include ipad_landscape {
      margin: 50px auto;
      width: calc(100% - 20px);
    }
  }

  @include ipad_landscape {
    margin: 50px 10px;
  }
}

.img-container {
  position:relative;

  &__img {
    width: 100%;
    transition: 0.5s filter;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    position: absolute;
    filter: saturate(10%);

    &:hover {
      filter:none;
    }

  }
}

.spinner {
  position:absolute;
  left: 50%;
  top: 50%;
  @include absolute_center_middle;
}

.menu {
  width: auto;

  @include desktop {
  }
  @include ipad_landscape {
    width: 30%;
  }
  &__link {
    margin-left: 15px;
    @include bold_type;
    font-size:1.3em;

    &--first {
      @extend .menu__link;
      margin:0;
    }

    @include ipad_landscape {
      font-size:1.3em;
    }
  }
}

.list-grid {
  margin-top: 30px;
  display:flex;

  &__link {
    margin-right: 10px;
    @include bold_type;
    font-size:1.3em;

    @include ipad_landscape {
      font-size:1.3em;
    }
  }
}

.border {
  &--10 {
    margin-top: 13px;
    border-top: 1px solid black;
    margin-right: 10px;
    width: 30px;
  }

  &--1043 {
    @extend .border--10;
    margin-right: 43px;
  }
}

.articles-list {
  margin-top: 30px;

  &__year-area {
    display:flex;
    align-items: flex-start;
    border-bottom: 1px solid black;
    padding-bottom: 15px;
    margin-bottom: 15px;

    @include ipad_landscape {
      display: block;
    }
  }

  &__year {
    @include regular_type;
    font-size:1.3em;
    margin-top: 3px;
    margin-right: 30px;
    width: 45px;

    @include ipad_landscape {
      margin-bottom: 10px;
    }
  }

  &__list {
    width: calc(100% - 45px);
  }
}

.projects-list {
  margin-top: 30px;

  &__year-area {
    display:flex;
    align-items: flex-start;
    border-bottom: 1px solid black;
    padding-bottom: 15px;
    margin-bottom: 15px;

    @include ipad_landscape {
      display: block;
    }
  }

  &__year {
    @include regular_type;
    font-size:1.2em;
    margin-top: 3px;
    margin-right: 30px;
    width: 45px;
    @include ipad_landscape {
      margin-bottom: 5px;
    }

    @include phone {
      font-size:1.15em;
    }
  }

  &__list {
    width: calc(100% - 45px);
  }
}

.projects-grid {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.thumb-hidden {

  width: calc(25% - 25px);


  @include desktop {
    width: calc(33% - 20px);

  }

  @include ipad_landscape {
    width: calc(50% - 20px);
  }

  @include ipad_portrait {
    width: 100%;
  }

}

.project-thumb {
  margin-top:50px;
  width: calc(25% - 25px);


  &:hover {
    opacity: 1;
  }



  @include desktop {
    width: calc(33% - 20px);

  }

  @include ipad_landscape {
    width: calc(50% - 20px);
  }

  @include ipad_portrait {
    width: 100%;
  }



  &__title {
    @include bold_type;
    text-align: center;
    margin-top: 10px;
    font-size: 1.0em;

      
  }
}


.profile-paragraph {
  font-size:1.3em;
  // margin-top: 20px;

    @include ipad_landscape {
    font-size:1.2em;
  }
}

.project-title {
  margin-top:5px;
  @include bold_type;
  font-size:1.6em;
}

.profile-desc-cv {
  display:flex;
  align-items: flex-start;
  justify-content: space-between;
  @include ipad_landscape {

    display:block;
  }

  &__desc {
    width: 70%;

    @include ipad_landscape {
      width: auto;
    }

    @include phone {
      width: auto;
    }
  }

  &__cv {
    width: calc(30% - 50px);
    @include ipad_landscape {
      // margin-left: 100px;
      width: 40%;
      margin:0;
      margin:0;
    }

    @include phone {
      width: auto;
    }

    a {
      text-decoration: underline;
    }
  }
}

footer {
  padding: 100px 22px 50px;
  width: calc(100% - 44px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include ipad_landscape {
    padding: 100px 10px 50px;

  }
}

.footer {

  &__mail{
    @include bold_type;
    font-size:1.4em;

    @include ipad_landscape {
      font-size:1.2em;
    }
  }

  &__sm {
    @include bold_type;
    font-size:1.4em;
    width:255px;
    margin-right: 0;

    @include desktop {
      margin-right: 95px;
    }


    @include ipad_landscape {
      width: auto;
      font-size:1.2em;
      margin-right:0;
    }

  }
}

.project-ty {
  display: flex;
  justify-content: space-between;
  &__title {
    width:70%;

    @include ipad_landscape {
      width: calc(70% - 15px);
    }
  }
  
  &__year {

    width: calc(30% - 50px);

    margin-top: 20px;
    
    @include bold_type;
    font-size:1.3em;

    div {
      word-wrap: break-word;
  
    }
    
    @include ipad_landscape {
      width: 30%;
      margin-left:0;
      font-size: 1.2em;
    }
  }  
}

.project-info {
  margin-top: 150px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include phone {
    margin-top:100px;    
    display: block;
  }

  &__credit {
    width: calc(30% - 50px);
    p { 
      @include bold_type;
      font-size:1.1em;
      line-height:1.4;
      
      a {
        font-size: 1.0em;
        text-decoration: underline;
      }
    }


    @include ipad_landscape {
      width: 30%;
      margin:0;
    }

    @include phone {
      width: auto;
      margin:0;
    }
  }

  &__desc {
    width: 70%;

    @include ipad_landscape {
      width: calc(70% - 15px);
      margin:0;
    }


    @include phone {
      width: auto; 
    }

  }

  &__desc--en {
    @include regular_type;
    font-size:1.4em;
  }

  &__desc--ko {
    p { 
      @include regular_han_type;
      font-size:1.2em;
      line-height: 1.4;

      @include ipad_landscape {
        width: auto; 
      }
    }
  }

  &__link {
    @include bold_type;
    font-size:1.2em;

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

  }
}

.project-video {
  max-width: 1000px;
}

.project-pictures {
  margin-top: 100px;
}

.picture {
  max-width: 1000px;
  width: 100%;
  padding: 30px 0;
  img {
    width: 100%;
    border-width: 1px;
  }
}

.article-list {
  display:flex;
  margin-bottom: 10px;
  
  &__title {
    @include bold_type;
    width: 618px;
    font-size:1.5em;

    @include ipad_landscape {
      width: 100%;
      font-size:1.4em;
    }
  }

  &__authors {
    @include regular_type;
    font-size: 1.0em;
    margin-left: 20px;
  }
  &__publisher {
    @include regular_type;
    font-style: italic;
    font-size: 1.0em;
    margin-left: 20px;
  }
}

.project-list {
  display:flex;
  margin-bottom: 5px;
  
  &__title {
    @include bold_type;
    width: 618px;
    font-size:1.3em;

    @include ipad_landscape {
      width: calc(70% - 15px);
      margin-right:10px;
      font-size:1.3em;
    }

    @include phone {
      font-size: 1.2em;
    }
  }

  &__category {
    @include regular_type;
    font-size:1.3em;

    @include ipad_landscape {
      width: 30%;
      font-size:1.3em;
    }

    @include phone {
      font-size: 1.2em;
    }
  }
}

.video {

  max-width: 1000px;
  width: 100%;
  padding: 30px 0;
  video {
    width: 100%;
  }
}


.lightbox {
  position:fixed;
  width:100%;
  height: 100%;
  z-index:8;
  left:0;
  top:0;

  &__close-area {
    position:absolute;
    right: 20px;
    top: 20px;
    z-index:9;
  }

  &__img-area {
    position:absolute;
    z-index:9;
    left: 50%;
    top: 50%;
    max-width: 1200px;
    width: calc(100% - 200px);
    
    @include ipad_landscape {
      width: calc(100% - 20px);
    }

    @include absolute_center_middle;
    img {
      width: 100%;
    }
  }

  &__prev-area {
    position:absolute;
    z-index:9;
    left:20px;
    top: 50%;
    background-color: white;
    padding: 5px 7px;
    border-radius: 15px;
    @include absolute_middle;
    @include ipad_landscape {
      transform: none;
      top: initial;
      bottom: 20px;
    }
  }

  &__next-area {
    position:absolute;
    z-index:9;
    right:20px;
    top: 50%;
    background-color: white;
    padding: 5px 7px;
    border-radius: 15px;
    @include absolute_middle;

     @include ipad_landscape {
      transform: none;
      top: initial;
      bottom: 20px;
    }
  }
}

.profile-photo {
  width: 40%;
  max-width: 200px;
  border-radius: 400px;
  margin-bottom: 10px;
  transition: 0.5s filter;
  filter: saturate(10%);

  &:hover {
    filter:none;
  }

}

.research-areas {
  list-style-type: disc;
  margin-left: 15px;
  li {
    font-size:1.3em;
    margin-bottom:5px;
  }
}

ul.articles {
  list-style-type: disc;
  margin-left: 15px;

  ul {
    list-style-type:circle;
    margin-left: 30px;
    margin-top: 5px;
  }
}

.sub-article {
  font-size:1.0em;
}

.article{
  font-size:1.3em;
  margin-bottom:20px;

    @include ipad_landscape {
      font-size:1.2em;
    }
}